@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Barlow", sans-serif;
}
.mobile-s {
  display: none;
}
.tablet-s {
  display: none;
}
.tablet-nav {
  display: none;
}
.color-light-blue {
  color: #4b87be;
  text-decoration: none;
}
.color-dark-blue {
  color: #204d86;
  text-decoration: none;
}
.color-yellow {
  color: #ffb703;
  text-decoration: none;
}
.color-black {
  color: #011c27;
  text-decoration: none;
}
.bg-light-blue {
  background-color: #4b87be !important;
}
.bg-dark-blue {
  background-color: #204d86 !important;
}
.bg-yellow {
  background-color: #ffb703;
}
.bg-black1 {
  background-color: #011c27;
}
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.fs-50 {
  font-size: 50px !important;
}
.fs-45 {
  font-size: 45px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-35 {
  font-size: 35px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-25 {
  font-size: 25px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-23 {
  font-size: 23px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.owl-carousel .owl-item img {
  object-fit: cover !important;
  width: 425px !important;
  height: 500px !important;
}
.landingSliderImage {
  width: 100%;
}
.sliderCardHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #203e28;
  margin: 24px 0px 2px;
}

.imageCards {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .desktop-screen {
    display: none;
  }
  .tablet-s {
    display: block;
  }
  .owl-carousel .owl-item img {
    width: 325px !important;
    height: 400px !important;
  }
}
