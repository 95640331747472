.mainHero {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #203e28;
}

.heroContent {
  display: flex;
  align-items: center;
  width: 50%;
  height: 680px;
  padding: 0px 86px;
  background-image: url(../../assets/hero-bg.svg);
  background-repeat: no-repeat;
  background-position: right 32px bottom 40px;
}

.heroHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 54px;
  color: #e2f2a8;
  margin: 0;
  padding-bottom: 24px;
}

.heroParagraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 24px;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .mainHero {
    flex-direction: column !important;
  }
  .mainHero img {
    width: 100%;
    max-width: 425px;
    height: auto;
  }
  .heroContent {
    width: auto;
    height: auto;
    padding: 16px 32px;
  }
  .heroHeading {
    font-size: 32px;
    line-height: 40px;
  }
  .heroParagraph {
    font-size: 20px;
    line-height: 28px;
  }
  .heroContent div button {
    width: 100% !important;
  }
}
