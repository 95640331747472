.pageHeader {
  height: 182px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 80px;
}

.pageHeader h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 54px;
  text-align: center;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .pageHeader {
    height: 120px !important;
    padding: 0px 16px;
  }
  .pageHeader h1 {
    font-size: 28px;
    line-height: 36px;
  }
}
