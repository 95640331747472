.form-container {
    position: absolute;
    background-color: white;
    border-radius: 6px;
}

.form-title h3 {
    font-family: Poppins-Medium, sans-serif;
    font-weight: 500;
    margin: 0;
}

.form-description {
    display: flex;
    align-items: center;
}

.form-description p {
    font-weight: 400;
}

.form-container .email-form {
    display: flex;
    flex-direction: column;
    background-color: #203E28;
    justify-content: center;
    align-items: center;
}

.form-container .email-form input {
    border-radius: 6px;
}

.email-form input[type=submit] {
    background-color: #e2f2a8;
    color: #203E28;
    border-radius: 6px;
}

@media screen and (max-width: 389px) {
    .form-container {
        display: none;
    }
}

@media screen and (min-width: 390px) and (max-width: 426px){
    .form-container {
        display: none;
    }
}

@media screen and (min-width: 427px) and (max-width: 767px) {
    .form-container {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .form-container {
        display: none;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .form-container {
        width: 300px;
        top: 670px;
        right: 90px;
    }

    .form-description {
        margin: 12px 32px;
    }

    .form-description p {
        font-size: 12px;
        margin: 0;
    }

    .form-container .form-title h3{
        font-size: 20px;
    }

    .form-container .form-title {
        background-color: #E2F2A8;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }

    .email-form input:first-child {
        margin-top: 24px;
    }

    .email-form input:last-child {
        margin-bottom: 24px;
    }

    .email-form input {
        height: 40px;
        margin: 14px;
        text-indent: 16px;
        width: 90%;
        font-size: 16px;
    }

    .email-form input[type=submit] {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .form-container {
        width: 310px;
        top: 660px;
        right: 210px;
    }

    .form-description {
        margin: 12px 32px;
    }

    .form-description p {
        font-size: 12px;
        margin: 0;
    }

    .form-container .form-title h3{
        font-size: 20px;
    }

    .form-container .form-title {
        background-color: #E2F2A8;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
    }

    .email-form input:first-child {
        margin-top: 24px;
    }

    .email-form input:last-child {
        margin-bottom: 24px;
    }

    .email-form input {
        height: 40px;
        margin: 16px;
        text-indent: 16px;
        width: 90%;
        font-size: 18px;
    }

    .email-form input[type=submit] {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1529px) {
    .form-container {
        width: 380px;
        top: 1000px;
        right: 225px;
    }

    .form-description {
        margin: 12px 32px;
    }

    .form-description p {
        font-size: 14px;
        margin: 0;
    }

    .form-container .form-title h3{
        font-size: 20px;
    }

    .form-container .form-title {
        background-color: #E2F2A8;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
    }

    .email-form input:first-child {
        margin-top: 24px;
    }

    .email-form input:last-child {
        margin-bottom: 24px;
    }

    .email-form input {
        height: 50px;
        margin: 12px;
        text-indent: 16px;
        width: 90%;
        font-size: 18px;
    }

    .email-form input[type=submit] {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
    }
}

@media screen and (min-width: 1529px) and (max-width: 1679px) {
    .form-container {
        width: 380px;
        top: 1000px;
        right: 260px;
    }
    .form-description {
        margin: 12px 32px;
    }

    .form-description p {
        font-size: 14px;
        margin: 0;
    }

    .form-container .form-title h3{
        font-size: 20px;
    }

    .form-container .form-title {
        background-color: #E2F2A8;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
    }

    .email-form input:first-child {
        margin-top: 24px;
    }

    .email-form input:last-child {
        margin-bottom: 24px;
    }

    .email-form input {
        height: 50px;
        margin: 12px;
        text-indent: 16px;
        width: 90%;
        font-size: 18px;
    }

    .email-form input[type=submit] {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
    }
}

@media screen and (min-width: 1680px) and (max-width: 1799px) {
    .form-container {
        width: 481px;
        top: 960px;
        right: 270px;
    }

    .form-description {
        margin: 20px 32px;
    }

    .form-description p {
        font-size: 20px;
        margin: 0;
    }

    .form-container .form-title {
        background-color: #E2F2A8;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90px;
    }

    .email-form input:first-child {
        margin-top: 24px;
    }

    .email-form input:last-child {
        margin-bottom: 24px;
    }

    .email-form input {
        height: 70px;
        margin: 14px;
        text-indent: 24px;
        width: 90%;
        font-size: 24px;
    }

    .email-form input[type=submit] {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 600;
    }
}

@media screen and (min-width: 1800px) {
    .form-container {
        width: 481px;
        top: 960px;
        right: 335px;
    }

    .form-description {
        margin: 20px 32px;
    }

    .form-description p {
        font-size: 20px;
        margin: 0;
    }

    .form-container .form-title {
        background-color: #E2F2A8;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90px;
    }

    .email-form input:first-child {
        margin-top: 24px;
    }

    .email-form input:last-child {
        margin-bottom: 24px;
    }

    .email-form input {
        height: 70px;
        margin: 14px;
        text-indent: 24px;
        width: 90%;
        font-size: 24px;
    }

    .email-form input[type=submit] {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 600;
    }
}




