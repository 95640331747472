.meshSection {
  padding: 64px 0px;
  background-color: #e8ede5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meshHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #203e28;
  margin: 0;
}

.meshCards {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 75px;
}

.meshCard {
  background-color: #ffffff;
  padding: 32px 20px;
  max-width: 264px;
  height: 286px;
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.meshCardHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #203e28;
  margin-top: 24px;
  margin-bottom: 8px;
}

.meshCardDescription {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2d2926;
  margin-bottom: 16px;
}

.meshCardLink {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-decoration-line: underline !important;
  color: #203e28 !important;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .meshSection {
    padding: 40px 16px;
  }
  .meshHeading {
    font-size: 20px;
    line-height: 28px;
  }
  .meshCards {
    flex-direction: column;
    margin-top: 24px;
    gap: 16px;
  }
}
