.protectionSection {
  padding: 64px 170px;
  background-color: #ffffff;
}

.protectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.protectionHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #5f725d;
  margin: 0;
  margin-bottom: 16px;
}

.protectionDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #2d2926;
  margin: 0;
  margin-bottom: 40px;
}

.protectionCards {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}

.protectionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 340px;
}

.pCards2 {
  margin-top: 48px;
}

.pCardHeaing {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #203e28;
  margin: 24px 0px 2px;
}

.pCardDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2d2926;
  margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .protectionSection {
    padding: 32px 16px;
  }
  .protectionHeading {
    font-size: 24px;
    line-height: 32px;
  }
  .protectionDescription {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .protectionCards {
    flex-direction: column;
    align-items: center;
  }
  .pCards2 {
    margin-top: 32px;
  }
}
