.mailForm {
  padding: 64px;
  background-color: #203e28;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formContent {
  width: 50%;
  padding-left: 64px;
}

.formHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #e2f2a8;
  margin: 0;
  margin-bottom: 16px;
}

.inputFields {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.inputFieldResponsive {
  margin-left: 16px !important;
}

.inputField {
  background-color: #ffffff;
  border-radius: 4px;
  max-width: 294px;
  width: 100%;
  max-height: 48px;
}

.inputAddress {
  max-width: 478px;
  margin-right: 16px !important;
}

.inputField label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #757575;
}

.inputField .Mui-focused {
  color: #757575 !important;
}

.inputField div:before,
.inputField div:after {
  border-bottom: none !important;
}

.inputField:focus {
  border: 2px solid #e2f2a8;
}

.inputField div input {
  padding: 20px 12px 8px !important;
}

.addressText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  margin-top: 24px;
  margin-bottom: 8px;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .mailForm {
    padding: 32px 16px;
    flex-direction: column-reverse;
  }
  .formContent {
    width: auto;
    padding-left: 0;
    margin-bottom: 32px;
  }
  .formHeading {
    font-size: 24px;
    line-height: 32px;
  }
  .inputFields {
    flex-wrap: wrap;
  }
  .inputField {
    max-width: 400px !important;
    width: 100% !important;
  }
  .inputFieldResponsive {
    margin-left: 0px !important;
    margin-top: 24px !important;
  }
  .addressText {
    font-size: 16px;
    line-height: 24px;
  }
  .inputAddress {
    margin-right: 0 !important;
  }
  .inputFields button {
    margin-top: 24px;
    width: 100%;
  }
  .mailForm img {
    width: 100%;
    max-width: 425px;
    height: auto;
  }
}
