.accordion-wrapper .accordion-item h3.accordion-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
}

.accordion-wrapper .accordion-item h3.accordion-title button {
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  text-align: left;
  color: #203e28;
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding-left: 0;
}

.accordion-wrapper
  .accordion-item
  h3.accordion-title
  button
  span.title-wrapper {
  display: block;
  position: relative;
  width: 100%;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper {
  width: 10%;
  display: flex;
  justify-content: end;
}

.accordion-wrapper
  .accordion-item
  h3.accordion-title
  button
  span.icon-wrapper
  span.minus {
  content: url(../../assets/icons/plus.svg);
  /* content: url(../../assets/icons/line.svg); */
  width: 24px;
  height: 24px;
}

.accordion-wrapper
  .accordion-item
  h3.accordion-title
  button
  span.icon-wrapper
  span.plus {
  content: url(../../assets/icons/line.svg);
  /* content: url(../../assets/icons/plus.svg); */
  width: 24px;
  height: 24px;
  transform: rotate(0deg);
  transition-timing-function: ease-in;
  transition: all 0.2s;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-close {
  box-sizing: border-box;
  /* padding: 0.5rem 1rem 0 1rem; */
  opacity: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease-out;
  transition: all 0.2s;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-open {
  overflow: hidden;
  box-sizing: border-box;
  opacity: 1;
  width: 100%;
  height: auto;
  transition-timing-function: ease-in;
  transition: all 0.2s;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2d2926;
  padding: 8px 0px;
}

.accordion-wrapper .accordion-item .accordion-panel p {
  margin: 0;
}

.accordion-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 0px;
}

.accordion-wrapper form {
  max-width: 680px;
}

.accordion-wrapper form .accordion-item:first-child {
  border-top: 3px solid #e8ede5;
}

.accordion-item {
  padding: 12px 0px;
  border-bottom: 3px solid #e8ede5;
}

.accordion-item:hover {
  cursor: pointer;
}

.accordion-title:hover {
  cursor: pointer;
}

.title-wrapper:hover {
  cursor: pointer;
}

.icon-wrapper:hover {
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .accordion-wrapper {
    padding: 26px 16px;
  }
  .accordion-item {
    padding: 22px 0px;
  }
  .accordion-wrapper .accordion-item .accordion-panel .panel-open {
    padding: 16px 0px;
  }
}
