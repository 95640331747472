.social-review {
    display: flex;
    flex-direction: column;
}

.social-review .title h1 {
    color: #5F725D;
}

.review {
    display: flex;
    width: 100%;
}

.customer {
    display: flex;
}

.customer-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.profile-data p {
    font-weight: 400;
    text-align: left;
}

@media screen and (max-width: 389px) {
    .social-review {
        margin: 36px 32px;
        flex-direction: column;
        align-items: center;
    }

    .title {
        margin-bottom: 20px;
    }

    .social-review .title h1 {
        font-size: 20px;
        margin: 0;
    }

    .review {
        height: 390px;
        justify-content: space-between;
        flex-direction: column;
    }

    .customer {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .customer-quote {
        text-align: center;
        margin: 12px 14px 12px 16px;
    }

    .customer .starts {
        margin: 0;
    }

    .customer .starts span {
        font-size: 20px;
        margin: 0 0 0 5px;
    }

    .customer-details img {
        width: 41px;
        height: 41px;
    }

    .profile-data {
        display: flex;
        flex-direction: column;
    }

    .profile-data p {
        font-size: 12px;
        margin: 0 0 0 20px;
    }
}

@media screen and (min-width: 390px) and (max-width: 426px) {
    .social-review {
        margin: 32px 32px;
        flex-direction: column;
        align-items: center;
    }

    .title {
        margin-bottom: 20px;
    }

    .social-review .title h1 {
        font-size: 24px;
        margin: 0;
    }

    .review {
        height: 414px;
        justify-content: space-between;
        flex-direction: column;
    }

    .customer {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .customer-quote {
        text-align: center;
        margin: 16px 18px 16px 20px;
    }

    .customer .starts {
        margin: 0;
    }

    .customer .starts span {
        font-size: 24px;
        margin: 0 0 0 6px;
    }

    .customer-details img {
        width: 51px;
        height: 51px;
    }

    .profile-data {
        display: flex;
        flex-direction: column;
    }

    .profile-data p {
        font-size: 14px;
        margin: 0 0 0 20px;
    }

}

@media screen and (min-width: 427px) and (max-width: 767px) {
    .social-review {
        margin: 32px 32px;
        flex-direction: column;
        align-items: center;
    }

    .title {
        margin-bottom: 20px;
    }

    .social-review .title h1 {
        font-size: 26px;
        margin: 0;
    }

    .review {
        height: 400px;
        justify-content: space-between;
        flex-direction: column;
    }

    .customer {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .customer-quote {
        text-align: center;
        margin: 16px 50px 16px 50px;
        font-size: 16px;
    }

    .customer .starts {
        margin: 0;
    }

    .customer .starts span {
        font-size: 28px;
        margin: 0 0 0 8px;
    }

    .customer-details img {
        width: 51px;
        height: 51px;
    }

    .profile-data {
        display: flex;
        flex-direction: column;
    }

    .profile-data p {
        font-size: 14px;
        margin: 0 0 0 20px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .social-review {
        margin: 36px 40px;
        flex-direction: column;
        align-items: center;
    }

    .title {
        margin-bottom: 20px;
    }

    .social-review .title h1 {
        font-size: 36px;
        margin: 0;
    }

    .review {
        height: 390px;
        justify-content: space-between;
        flex-direction: column;
    }

    .customer {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .customer-quote {
        text-align: center;
        margin: 16px 50px 16px 50px;
        font-size: 16px;
    }

    .customer .starts {
        margin: 0;
    }

    .customer .starts span {
        font-size: 28px;
        margin: 0 0 0 8px;
    }

    .customer-details img {
        width: 61px;
        height: 61px;
    }

    .customer-details .profile-data {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .profile-data p {
        font-size: 16px;
        margin: 0 0 0 20px;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .social-review {
        margin: 64px 120px;
        flex-direction: column;
        align-items: center;
    }

    .review {
        justify-content: space-evenly;
        flex-direction: row;
        height: 100%;
    }

    .customer {
        width: 352px;
        flex-direction: column;
        text-align: center;
    }

    .customer-quote {
        font-size: 20px;
        margin: 0 0 40px 0;
        height: 120px;
    }

    .customer .starts {
        margin: 42px 0 28px 0;
    }

    .customer .starts span {
        font-size: 22px;
        margin-left: 8px;
    }

    .customer-details img {
        width: 55px;
        height: 55px;
    }

    .profile-data {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .profile-data p {
        font-size: 16px;
        margin: 0 0 0 20px;
    }

}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .social-review {
        margin: 64px 120px;
        flex-direction: column;
        align-items: center;
    }

    .review {
        justify-content: space-evenly;
        flex-direction: row;
        height: 100%;
    }

    .customer {
        width: 352px;
        flex-direction: column;
        text-align: center;
    }

    .customer-quote {
        font-size: 24px;
        margin: 0 0 40px 0;
    }

    .customer .starts {
        margin: 42px 0 28px 0;
    }

    .customer .starts span {
        font-size: 28px;
        margin-left: 8px;
    }

    .customer-details img {
        width: 60px;
        height: 60px;
    }
    .profile-data {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .profile-data p {
        font-size: 18px;
        margin: 0 0 0 20px;
    }

}

@media screen and (min-width: 1440px) and (max-width: 1679px) {
    .social-review {
        margin: 64px 120px;
        flex-direction: column;
        align-items: center;
    }
    .social-review .title h1{
        margin: 0;
    }
    .review {
        justify-content: space-evenly;
        flex-direction: row;
        height: 100%;
    }

    .customer {
        width: 412px;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        margin: 0 auto;
    }

    .customer-quote {
        font-size: 24px;
        margin: 0 0 32px 0;
    }

    .customer .starts {
        margin: 48px 0 32px 0;
    }

    .customer .starts span {
        font-size: 28px;
        margin-left: 8px;
    }

    .customer-details img {
        width: 60px;
        height: 60px;
    }
    .profile-data {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .profile-data p {
        font-size: 18px;
        margin: 0 0 0 20px;
    }

}

@media screen and (min-width: 1680px) {
    .social-review {
        margin: 64px 120px;
        flex-direction: column;
        align-items: center;
    }

    .review {
        justify-content: space-evenly;
        flex-direction: row;
    }

    .customer {
        width: 352px;
        flex-direction: column;
        text-align: center;
        justify-content: space-evenly;
    }

    .customer-quote {
        font-size: 24px;
        margin: 0 0 40px 0;
    }

    .customer .starts {
        margin: 56px 0 40px 0;
    }

    .customer .starts span {
        font-size: 33px;
        margin-left: 8px;
    }

    .customer-details img {
        width: 67px;
        height: 67px;
    }

    .profile-data p {
        font-size: 20px;
        margin: 0 0 0 20px;
    }

}




