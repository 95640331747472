.partnersSection {
  padding: 64px 60px;
}

.partnerHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #5f725d;
  margin: 0;
}

.partnersCards {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 24px;
  gap: 24px;
}

.partnerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-width: 200px;
  height: 200px;
}

.partnerImageContainer {
  height: 124px !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.partnerDetail {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #203e28;
  height: 80px;
  margin-top: 16px;
  margin-bottom: 0;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .partnersSection {
    padding: 40px 16px;
  }
  .partnerHeading {
    font-size: 24px;
    line-height: 32px;
  }
  .partnersCards {
    flex-wrap: wrap;
  }
  .partnerCard {
    max-width: 144px;
  }
  .partnerDetail {
    font-size: 14px;
    line-height: 20px;
  }
}
