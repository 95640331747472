.newsSection {
  display: flex;
  justify-content: center;
  padding: 48px 0px 64px;
}
.newsDetail {
  max-width: 680px;
}
.newsDetail h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #203e28;
  margin-top: 0;
}

.newsDetail .firstPara {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #2d2926;
  margin-bottom: 40px;
}

.newsDetail img {
  max-width: 680px;
  margin-bottom: 34px;
}

.newsDetail p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2d2926;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .newsSection {
    padding: 40px 16px;
  }
  .newsDetail {
    max-width: 100%;
  }
  .newsDetail img {
    max-width: 100%;
  }
  .newsDetail h3 {
    font-size: 20px;
    line-height: normal;
  }
  .newsDetail .firstPara {
    font-size: 18px;
    line-height: 28px;
  }
}
