.landingSlider {
  padding: 64px 200px;
}

.landingSliderHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #5f725d;
  margin: 16px;
}

.sliderCards {
  margin-top: 48px;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .landingSlider {
    padding: 32px 16px !important;
  }
  .landingSliderHeading {
    font-size: 24px;
    line-height: 32px;
  }
  .sliderCardHeading {
    font-size: 20px;
  }
}
