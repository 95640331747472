.visualDesignSection {
  padding: 64px 260px;
  background-color: #e8ede5;
  display: flex;
  flex-direction: column;
}

.visualDesignHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #203e28;
  margin: 0;
}

.visualDesignP1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #2d2926;
  margin-top: 16px;
  margin-bottom: 24px;
}

.visualDesignP2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2d2926;
  margin-bottom: 0;
  margin-top: 40px;
}

.mobileImage {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .visualDesignSection {
    padding: 40px 16px;
  }
  .visualDesignHeading {
    font-size: 24px;
    line-height: 32px;
  }
  .visualDesignP1 {
    font-size: 16px;
    line-height: 24px;
  }
  .visualDesignP2 {
    font-size: 16px;
    line-height: 24px;
  }
  .desktopImage {
    display: none;
  }
  .mobileImage {
    display: block;
  }
}
