.homeSection {
  padding: 64px 80px;
  background-color: #e8ede5 !important;
}

.serviceHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #203e28;
  margin: 0;
  margin-bottom: 16px;
}

.serviceDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2d2926;
  margin: 0;
}

.homeCard {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap !important;
  justify-content: center;
  gap: 75px;
  margin-top: 40px;
}

.serviceCard {
  max-width: 264px;
}

.serviceCardHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #203e28;
  margin: 0;
  margin-top: 16px;
}

.serviceCardDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2d2926;
  margin: 8px 0px;
}

.serviceCardLink {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #203e28;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .homeSection {
    padding: 40px 16px;
  }
  .serviceHeading {
    font-size: 24px;
    line-height: 32px;
  }
  .serviceDescription {
    font-size: 16px;
    line-height: 24px;
  }
  .homeCard {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .serviceCard {
    max-width: 394px;
  }
  .serviceCard img {
    width: 100%;
    max-width: 394px;
    height: auto;
  }
}
