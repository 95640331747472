.featureCards {
  background-color: #e8ede5;
  padding: 24px 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.featureCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
}

.featureDescription {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #203e28;
  padding-top: 8px;
  margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .featureCards {
    padding: 16px;
  }
  .featureCard img {
    width: 32px;
    height: 32px;
  }
  .featureDescription {
    font-size: 8px;
    line-height: 10px;
  }
}
